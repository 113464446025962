<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CustomObjects.ViewFilters",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <router-link
        :to="{
          path: '/ProjectDesign/ViewFilter/List',
        }"
        class="btn btn-action-list-page"
      >
        <i class="bi bi-list"></i>
        {{
          $t(
            "ViewFilters.AllData",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>
      <router-link
        :to="{
          name: 'CustomObjectNewViewFilter',
          params: { customObjectId: this.$route.params.customObjectId },
        }"
        class="btn btn-success btn-action-new-page"
      >
        <i class="bi bi-plus"></i>
        {{
          $t(
            "ViewFilters.NewButton",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>
    </div>
  </div>
  <ul class="nav nav-tabs mb-2 mt-0" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="pills-view-filters-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-view-filters"
        type="button"
        role="tab"
        aria-selected="true"
        @click="viewFilterTabClick('view')"
      >
        {{
          $t(
            "ViewFilters.ViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="pills-system-filters-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-system-filters"
        type="button"
        role="tab"
        aria-selected="false"
        @click="viewFilterTabClick('system')"
      >
        {{
          $t(
            "ViewFilters.SystemViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </li>
  </ul>
  <div class="tab-content">
    <div
      class="tab-pane fade active show"
      id="pills-view-filters"
      role="tabpanel"
    >
      <Grid
        v-if="!showSystemViewFilterGrid"
        ref="viewFilterGrid"
        :settings="gridSettings"
        :columns="gridColumns"
        @copyViewFilterButtonClick="cloneViewFilter"
        @onTranslationButtonClick="onTranslationButtonClick"
      />
    </div>
    <div class="tab-pane fade" id="pills-system-filters" role="tabpanel">
      <Grid
        v-if="showSystemViewFilterGrid"
        ref="systemViewFilterGrid"
        :settings="gridSystemViewFilterSettings"
        :columns="gridColumns"
        @copyViewFilterButtonClick="cloneSystemViewFilter"
      />
    </div>
  </div>
  <TranslationModal
    :keyTypeId="keyTypeId"
    :parentPublicId="customObjectId"
    :formulaName="formulaName"
    :keyName="this.keyName"
    :keyItemPublicId="keyItemPublicId"
  />
</template>
<script>
import { showModal } from "@/core/helpers/dom";
import { createToast } from "mosha-vue-toastify";
export default {
  name: "CustomObjectViewFilters",
  data() {
    return {
      keyTypeId: 3,
      keyName: "",
      customObjectId: "",
      formulaName: "",
      keyItemPublicId: "",
      gridSettings: {
        action: "CustomObjectViewFilters",
        requestUrl: String.format(
          "/Lcdp-ViewFilterListViewFilters?listTypeId=0"
        ),
        requestUrlRouteParamNames: [
          { key: "customObjectPublicId", value: "customObjectId" },
        ],
        routeRequiredParameters: [
          {
            key: "viewFilterId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        allowSearchPanel: true,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        autoOrderProcess: true,
        deleteActionUrl: "/Lcdp-ViewFilterDelete",
        deleteBeforeActionUrl: "/Lcdp-ViewFilterCheckDelete",
        changeOrderUrl: "",

        isGatewayRequest: true,

        serialNoLink: `#/CustomObject/EditViewFilter/__CUSTOMOBJECTPUBLICID__&viewFilterId=`,

        buttons: [
          {
            name: "pageFilter",
            cssClass: "btn-info",
            iconClass: "bi-funnel-fill",
            routeButton: true,
            routeObj: {
              name: "CustomObjectViewFilterEditPageFilter",
              params: {
                customObjectId: this.$route.params.customObjectId,
                viewFilterId: "",
              },
            },
            iconAttributes: {
              "data-bs-html": "true",
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "ViewFilters.PageFilter",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "copyViewFilter",
            cssClass: "btn-primary",
            iconClass: "bi-subtract",
            routeButton: false,
            emitMethodName: "copyViewFilterButtonClick", //emiting
            iconAttributes: {
              "data-bs-html": "true",
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "BaseModelFields.Copy",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "editViewFilter",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectEditViewFilter",
              params: {
                customObjectId: this.$route.params.customObjectId,
                viewFilterId: "",
              },
            },
          },
        ],
      },
      gridSystemViewFilterSettings: {
        action: "CustomObjectSystemViewFilters",
        requestUrl: String.format(
          "/Lcdp-ViewFilterListViewFilters?listTypeId=1"
        ),
        requestUrlRouteParamNames: [
          { key: "customObjectPublicId", value: "customObjectId" },
        ],
        routeRequiredParameters: [
          {
            key: "viewFilterId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        allowSearchPanel: true,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        autoOrderProcess: true,
        deleteActionUrl: "/Lcdp-ViewFilterDelete",
        deleteBeforeActionUrl: "/Lcdp-ViewFilterCheckDelete",
        changeOrderUrl: "",

        isGatewayRequest: true,

        serialNoLink: `#/CustomObject/EditViewFilter/__CUSTOMOBJECTPUBLICID__&viewFilterId=`,

        buttons: [
          {
            name: "pageFilter",
            cssClass: "btn-info",
            iconClass: "bi-funnel-fill",
            routeButton: true,
            routeObj: {
              name: "CustomObjectViewFilterEditPageFilter",
              params: {
                customObjectId: "",
                viewFilterId: "",
              },
            },
            iconAttributes: {
              "data-bs-html": "true",
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "ViewFilters.PageFilter",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "copyViewFilter",
            cssClass: "btn-primary",
            iconClass: "bi-subtract",
            routeButton: false,
            emitMethodName: "copyViewFilterButtonClick", //emiting
            iconAttributes: {
              "data-bs-html": "true",
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "BaseModelFields.Copy",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "editViewFilter",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectEditViewFilter",
              params: {
                customObjectId: "",
                viewFilterId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "ViewFilters.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ViewFilters.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ViewFilters.isDefault",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isDefaultFilter",
          type: "boolean",
          visible: true,
          width: 100,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
      showSystemViewFilterGrid: false,
    };
  },
  methods: {
    viewFilterTabClick(type) {
      this.showSystemViewFilterGrid = type === "system";
    },
    cloneFilter(type, rowData) {
      this.$prodGatewayAxios
        .get(`/Lcdp-ViewFilterClone?id=${rowData.publicId}`)
        .then((response) => {
          var result = response.data;
          if (result.isOk) {
            if (type == 0) {
              this.$refs.viewFilterGrid.grid.refresh();
            } else {
              this.$refs.systemViewFilterGrid.grid.refresh();
            }
          } else {
            createToast(result.message, {
              showIcon: true,
              position: "top-right",
              type: "danger",
              transition: "zoom",
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    cloneViewFilter(rowData) {
      this.cloneFilter(0, rowData);
    },
    cloneSystemViewFilter(rowData) {
      this.cloneFilter(1, rowData);
    },
    onTranslationButtonClick(rowData) {
      this.keyItemPublicId = rowData.publicId;
      this.customObjectId = rowData.customObjectPublicId;
      this.formulaName = rowData.formulaName;
      this.keyName = rowData.name;
      // showModal(document.getElementById("translationModal"));
    },
  },
  mounted() {
    if (this.$store.getters._isMultiLanguage) {
      this.gridSettings.buttons.push({
        name: "translation",
        cssClass: "btn-light translation",
        iconClass: "bi bi-translate",
        routeButton: false,
        emitMethodName: "onTranslationButtonClick",
        attributes: {
          "data-bs-toggle": "modal",
          "data-bs-target": "#translationModal",
        },
        iconAttributes: {
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          title: this.$t(
            "Components.TranslationModal.Title",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      });
    }
  },
};
</script>
